@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a {
  text-decoration: none !important;
}

.MuiInput-root:before {
  display: none;
}
.MuiStepConnector-root {
  left: calc(-50% + 40px) !important;
}

.footer-menu-link {
  cursor: pointer;
  display: block;
  text-decoration: none;
  padding: 7px 6px;
  color: black;
}

.footer-menu-link:hover {
  background: #c10000;
  color: white !important;
}